$(document).ready(function($){
    function openNav() {
        document.getElementById("overlay-navbar").style.display = "block";
        //document.getElementById("overlay-navbar").style.opacity = "1";
        //document.getElementById("overlay-navbar").style.height = "100%";
    }
    function closeNav() {
        document.getElementById("overlay-navbar").style.display = "none";
        //document.getElementById("overlay-navbar").style.opacity = "0";
        //document.getElementById("overlay-navbar").style.height = "0%";
    }
    $('#overlay-toogler-open').on("click", function(e) {
        e.preventDefault();
        openNav();
    });
    $('#overlay-toogler-close').on("click", function(e) {
        e.preventDefault();
        closeNav();
    });
    $('.file-unique').on('change', function() {
        var parent = $(this).parent(); 
  
        filename = $(this).val().split('\\').pop();
        if (this.files.length > 1) {
            parent.find('label').html("Diversos arxius");
        } else if (this.files.length == 1) {
            parent.find('label').html(filename);
        }
  
        parent.find('label').addClass('con_archivo');
        parent.find('.eliminar').css('display', 'block');
    });
  
    $('body').on("click", ".eliminar", function() {
        var parent = $(this).parent();
        if (parent.hasClass("file-create")) {
            parent.parent().find(".file-create").remove();
        } else {
            parent.find('input[type=file]').val('');
            parent.find('label').html(parent.find('label').attr("data-orgtext"));
            parent.find('label').removeClass('con_archivo');
            $(this).css('display', 'none');
        }
  
    });
    function file_validation(){
      var result = true;
      $(".file-unique").each(function(i){
        if(!$(this).val()){
            alert($(this).attr('public-error'));
            result = false;
          }
      });
     
      return result;
    }
    $("#form-alta").on('submit',function(){
      if(!file_validation()){
        return false;
      }
   });
});